import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserData } from '../../providers/user-data';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private userData: UserData,
    private router: Router
  ) { }

/**
 * If the user is logged, allow the user to continue to the requested page, otherwise, is redirected
 * to the login page
 * @param route 
 * @param state 
 */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {

    return this.userData.isLoggedIn().then(loggedIn => {
      if (loggedIn) {
        return true;
      } else {
        console.error("You must login to access this page");
        this.router.navigate(['/login'], {
          queryParams: {
            return: state.url
          }
        });
        return false;
      }
    });
  }

  /**
   * If the user is logged, stops the user from returning to the login page, otherwise, is redirected
   * to the list devices page
   * @param route 
   * @param state 
   */
  canLoad(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {

    return this.userData.isLoggedIn().then(loggedIn => {
      if (!loggedIn) {
        return true;
      } else {
        console.error("You cannot access the login page unless you logout first");
        this.router.navigate(['/tabs/surebets'], {
          queryParams: {
            return: state.url
          }
        });
        return false;
      }
    });
  }

}