import { CalculatorPage2Module } from './pages/calculatorNewTaxa/calculator2.module';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/routeGuard/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'home',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'index',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./index/index.module').then( m => m.IndexPageModule)
  },
  {
    path: 'login',
    canLoad: [AuthGuardService],
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'signup',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'welcome',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'surebets-details',
    loadChildren: () => import('./surebets/surebets-details/surebets-details/surebets-details.module').then(m => m.SurebetsDetailsPageModule)
  },
  {
    path: 'surebets-casadinha',
    loadChildren: () => import('./surebets/surebets-casadinha/surebets-casadinha.module').then(m => m.SurebetsCasadinhaPageModule)
  },
  {
    path: 'calculator-details',
    loadChildren: () => import('./pages/calculatorNewTaxa/calculator2.module').then(m => m.CalculatorPage2Module)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
