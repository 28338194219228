import { ApiService } from './api/api.service';
import { Injectable } from '@angular/core';
import { User, SitesConfig } from './api/model/user';
import { ListaJogos,ListaMercados } from './api/model/jogos';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class SuremtsbetService {

  defaultBasePath: string = 'https://mtsapi.rodriguestech.com/api/v2';
  jogos: ListaJogos [];
  mercados: ListaMercados [];
  sitesconfig: SitesConfig [];
  baseaposta: number;


  constructor(
    private apiGateway: ApiService,
    public storage: Storage
  ) { }

  /**
  * Sets the list of devices
  * @param sitesconfig List of configs
  */
  setSiteConfigs(sitesconfig: SitesConfig[]) {
    this.sitesconfig = sitesconfig;
  }

  /**
  * Returns the list of devices
  */
  getSiteConfigs(): SitesConfig[] {
    return this.sitesconfig;
  }

  /**
   * Sets the list of devices
   * @param jogos List of devices
   */
  setJogos(jogos: ListaJogos[]) {
    this.jogos = jogos;
  }

  /**
   * Returns the list of devices
   */
  getJogos(): ListaJogos[] {
    return this.jogos;
  }

    /**
   * Sets the list of devices
   * @param jogosHand List of devices
   */
  setJogosHand(mercados: ListaMercados[]) {
      this.mercados = mercados;
  }
  
    /**
     * Returns the list of devices
     */
  getJogoshand(): ListaMercados[] {
      return this.mercados;
  }

  fixtexto(value: any, b: boolean){
    if(b){
      value = parseFloat(value.toFixed(4));
    }else{
      value = Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(value);
    }
    return value;
  }

  /**
   * Quando mudar a Cotação
   */
  async changeCotacao(oddsValueSite: number, getjogo: ListaJogos, oddstype: string) {
    //Pegar informações do Aporte.
    await this.storage.get("aportebasesave").then(aportebasesave => {
      if (aportebasesave != null) {
        this.baseaposta = aportebasesave;
      }
    });

    const jogos: ListaJogos[] = this.getJogos();
    if (jogos) {

    let jogo = jogos.find(jogo => jogo.id === getjogo.id)

    //Começar Calculo Surebet
    //vars 
    var premiocasa = 0;
    var premioempate = 0;
    var premiofora = 0;
    var aportecasa = 0;
    var aporteempate = 0;
    var aportefora = 0;
    var totaljogado = 0;
    var totalpremios = 0;
    var totalcomissao = 0;
    var comissaocasa = 0;
    var comissaoempate = 0;
    var comissaofora = 0;
    var lucrocasa = 0;
    var lucroempate = 0;
    var lucrofora = 0;
    var lucrototal = 0;
    var coeficienteCasa = 0;
    var coeficienteEmpate = 0;
    var coeficienteFora = 0;
    var surebet = 0;
    var profit = 0;
    var profitpercent = 0;

    var BestTaxaCasa = jogo.taxacasa.besttaxacasa;
    var BestTaxaEmpate = jogo.taxaempate.besttaxaempate;
    var BestTaxaFora = jogo.taxafora.besttaxafora;
    var BestLinkCasa = jogo.taxacasa.bestcasalink;
    var BestLinkEmpate = jogo.taxaempate.bestempatelink;
    var BestLinkFora = jogo.taxafora.bestforalink;
    var casacomissao =  jogo.casacomissao;
    var empatecomissao = jogo.empatecomissao;
    var foracomissao = jogo.foracomissao;


    if(oddstype == "casa"){
      for (var i = 0; i < jogo.taxacasa.odds.length; i++) {
          if (jogo.taxacasa.odds[i][1] == oddsValueSite){
            BestTaxaCasa = parseFloat(jogo.taxacasa.odds[i][0]);
            casacomissao = parseFloat(jogo.taxacasa.odds[i][2]);
            BestLinkCasa = jogo.taxacasa.odds[i][3];
          }
      }
    }
    if(oddstype == "casaComissao"){
        casacomissao = oddsValueSite;
    }
    if(oddstype == "casaOddsEdit"){
        BestTaxaCasa = oddsValueSite;
    }

    if(oddstype == "empate"){
      for (var i = 0; i < jogo.taxaempate.odds.length; i++) {
        if (jogo.taxaempate.odds[i][1] == oddsValueSite){
          BestTaxaEmpate = parseFloat(jogo.taxaempate.odds[i][0]);
          empatecomissao = parseFloat(jogo.taxaempate.odds[i][2]);
          BestLinkEmpate = jogo.taxaempate.odds[i][3];
        }
      }  
    }
    if(oddstype == "empateComissao"){
      empatecomissao = oddsValueSite;
    }

    if(oddstype == "empateOddsEdit"){
        BestTaxaEmpate = oddsValueSite;
    }


    if(oddstype == "fora"){
      for (var i = 0; i < jogo.taxafora.odds.length; i++) {
        if (jogo.taxafora.odds[i][1] == oddsValueSite){
          BestTaxaFora = parseFloat(jogo.taxafora.odds[i][0]);
          foracomissao = parseFloat(jogo.taxafora.odds[i][2]);
          BestLinkFora = jogo.taxafora.odds[i][3];
        }
      }  
    }
    if(oddstype == "foraComissao"){
      foracomissao = oddsValueSite;
    }

    if(oddstype == "foraOddsEdit"){
      BestTaxaFora = oddsValueSite;
    }

    //Iniciar Calculo
    coeficienteCasa = (1 / BestTaxaCasa);
    coeficienteEmpate = (1 / BestTaxaEmpate);
    coeficienteFora = (1 / BestTaxaFora);

    //Se a soma é menor que 1: É uma SureBet
    surebet = coeficienteCasa+coeficienteEmpate+coeficienteFora;
    profitpercent = (1 / (coeficienteCasa + coeficienteEmpate +  coeficienteFora) - 1) * 100;
    profitpercent = parseFloat(profitpercent.toFixed(2));
    profit = (this.baseaposta * profitpercent) / 100;
    profit = parseFloat(profit.toFixed(2));

    //Valor Aportado
    aportecasa = (this.baseaposta / surebet * coeficienteCasa);
    aporteempate = (this.baseaposta / surebet * coeficienteEmpate);
    aportefora = (this.baseaposta / surebet * coeficienteFora);
    aportecasa = parseFloat(aportecasa.toFixed(2));
    aporteempate = parseFloat(aporteempate.toFixed(2));
    aportefora = parseFloat(aportefora.toFixed(2));


    //converter depois do calculo
    surebet = parseFloat(surebet.toFixed(4));

    //Posivel Premio
    premiocasa = aportecasa * BestTaxaCasa;
    premioempate = aporteempate * BestTaxaEmpate;
    premiofora = aportefora * BestTaxaFora;
    premiocasa = parseFloat(premiocasa.toFixed(3));
    premioempate = parseFloat(premioempate.toFixed(3));
    premiofora = parseFloat(premiofora.toFixed(3));

    //Comissão de cada odds
    comissaocasa = (casacomissao/100) * aportecasa;
    comissaoempate = (empatecomissao/100) * aporteempate;
    comissaofora = (foracomissao/100) * aportefora;
    comissaocasa = parseFloat(comissaocasa.toFixed(3));
    comissaoempate = parseFloat(comissaoempate.toFixed(3));
    comissaofora = parseFloat(comissaofora.toFixed(3));

    //total das comissão.
    totalcomissao = comissaocasa+comissaoempate+comissaofora;      
    
    //Calculo Total
    totaljogado = aportecasa+aporteempate+aportefora;
    totaljogado = parseFloat(totaljogado.toFixed(2));
    totalpremios = ((premiocasa+premioempate+premiofora)/3) + totalcomissao;
    totalpremios = parseFloat(totalpremios.toFixed(2));

    //Lucro
    lucrocasa = (premiocasa - (aportecasa + aporteempate + aportefora)) + totalcomissao;
    lucroempate = (premioempate - (aportecasa + aporteempate + aportefora)) + totalcomissao;
    lucrofora = (premiofora - (aportecasa + aporteempate + aportefora)) + totalcomissao;
    lucrocasa = parseFloat(lucrocasa.toFixed(2));
    lucroempate = parseFloat(lucroempate.toFixed(2));
    lucrofora =  parseFloat(lucrofora.toFixed(2));
    //Lucro Total
    lucrototal = ((lucrocasa+lucroempate+lucrofora) / 3);
    lucrototal = parseFloat(lucrototal.toFixed(2));


    //SetVars
    jogo.casacomissao = casacomissao;
    jogo.empatecomissao = empatecomissao;
    jogo.foracomissao = foracomissao;
    jogo.taxacasa.besttaxacasa = BestTaxaCasa;
    jogo.taxacasa.bestcasalink = BestLinkCasa;
    jogo.taxaempate.besttaxaempate = BestTaxaEmpate;
    jogo.taxaempate.bestempatelink = BestLinkEmpate;
    jogo.taxafora.besttaxafora = BestTaxaFora;
    jogo.taxafora.bestforalink = BestLinkFora;

    jogo.calculo.surebet = surebet;
    jogo.calculo.profit = profit;
    jogo.calculo.profitpercent = profitpercent;
    
    jogo.calculo.aportecasa = aportecasa;
    jogo.calculo.aporteempate = aporteempate;
    jogo.calculo.aportefora = aportefora;

    jogo.calculo.premiocasa = premiocasa;
    jogo.calculo.premioempate = premioempate;
    jogo.calculo.premiofora = premiofora;
    jogo.calculo.totaljogado = totaljogado;
    jogo.calculo.totalpremios = totalpremios;
    jogo.calculo.lucrocasa = lucrocasa;
    jogo.calculo.casacomissao = casacomissao;
    jogo.calculo.lucroempate = lucroempate;
    jogo.calculo.empatecomissao = empatecomissao; 
    jogo.calculo.lucrofora = lucrofora; 
    jogo.calculo.foracomissao = foracomissao;
    jogo.calculo.lucrototal = lucrototal;
  }
}

  /**
   * Quando mudar a Cotação
   */
   async changeCotacaoHand(oddsValueSite: number, getjogo: ListaJogos, oddstype: string) {
    console.log(oddstype);


    //Pegar informações do Aporte.
    await this.storage.get("aportebasesave").then(aportebasesave => {
      if (aportebasesave != null) {
        this.baseaposta = aportebasesave;
      }
    });

    const jogos: ListaJogos[] = this.getJogoshand();
    if (jogos) {

    let jogo = jogos.find(jogo => jogo.id === getjogo.id)

    //Começar Calculo Surebet
    //vars 
    var premiocasa = 0;
    var premioempate = 0;
    var premiofora = 0;
    var aportecasa = 0;
    var aporteempate = 0;
    var aportefora = 0;
    var totaljogado = 0;
    var totalpremios = 0;
    var totalcomissao = 0;
    var comissaocasa = 0;
    var comissaoempate = 0;
    var comissaofora = 0;
    var lucrocasa = 0;
    var lucroempate = 0;
    var lucrofora = 0;
    var lucrototal = 0;
    var coeficienteCasa = 0;
    var coeficienteEmpate = 0;
    var coeficienteFora = 0;
    var surebet = 0;
    var profit = 0;
    var profitpercent = 0;

    var BestTaxaCasa = jogo.taxacasa.besttaxacasa;
    //var BestTaxaEmpate = jogo.taxaempate.besttaxaempate;
    var BestTaxaFora = jogo.taxafora.besttaxafora;
    var casacomissao =  jogo.casacomissao;
    var empatecomissao = jogo.empatecomissao;
    var foracomissao = jogo.foracomissao;


    if(oddstype == "casa"){
      for (var i = 0; i < jogo.taxacasa.odds.length; i++) {
          if (jogo.taxacasa.odds[i][1] == oddsValueSite){
            BestTaxaCasa = parseFloat(jogo.taxacasa.odds[i][0]);
            casacomissao = parseFloat(jogo.taxacasa.odds[i][2]);
          }
      }
    }
    if(oddstype == "casaComissao"){
        casacomissao = oddsValueSite;
    }
    if(oddstype == "casaOddsEdit"){
        BestTaxaCasa = oddsValueSite;
    }

    if(oddstype == "fora"){
      for (var i = 0; i < jogo.taxafora.odds.length; i++) {
        if (jogo.taxafora.odds[i][1] == oddsValueSite){
          BestTaxaFora = parseFloat(jogo.taxafora.odds[i][0]);
          foracomissao = parseFloat(jogo.taxafora.odds[i][2]);
        }
      }  
    }
    if(oddstype == "foraComissao"){
      foracomissao = oddsValueSite;
    }

    if(oddstype == "foraOddsEdit"){
      BestTaxaFora = oddsValueSite;
    }

    //Iniciar Calculo
    coeficienteCasa = (1 / BestTaxaCasa);
    coeficienteFora = (1 / BestTaxaFora);

    //Se a soma é menor que 1: É uma SureBet
    surebet = coeficienteCasa+coeficienteFora;
    profitpercent = (1 / (coeficienteCasa +  coeficienteFora) - 1) * 100;
    profitpercent = parseFloat(profitpercent.toFixed(2));
    profit = (this.baseaposta * profitpercent) / 100;
    profit = parseFloat(profit.toFixed(2));

    //Valor Aportado
    aportecasa = (this.baseaposta / surebet * coeficienteCasa);
    aportefora = (this.baseaposta / surebet * coeficienteFora);
    aportecasa = parseFloat(aportecasa.toFixed(2));
    aportefora = parseFloat(aportefora.toFixed(2));


    //converter depois do calculo
    surebet = parseFloat(surebet.toFixed(4));

    //Posivel Premio
    premiocasa = aportecasa * BestTaxaCasa;
    premiofora = aportefora * BestTaxaFora;
    premiocasa = parseFloat(premiocasa.toFixed(3));
    premiofora = parseFloat(premiofora.toFixed(3));

    //Comissão de cada odds
    comissaocasa = (casacomissao/100) * aportecasa;
    comissaofora = (foracomissao/100) * aportefora;
    comissaocasa = parseFloat(comissaocasa.toFixed(3));
    comissaofora = parseFloat(comissaofora.toFixed(3));

    //total das comissão.
    totalcomissao = comissaocasa+comissaofora;      
    
    //Calculo Total
    totaljogado = aportecasa+aportefora;
    totaljogado = parseFloat(totaljogado.toFixed(2));
    totalpremios = ((premiocasa+premiofora)/2) + totalcomissao;
    totalpremios = parseFloat(totalpremios.toFixed(2));

    //Lucro
    lucrocasa = (premiocasa - (aportecasa + aportefora)) + totalcomissao;
    lucrofora = (premiofora - (aportecasa + aportefora)) + totalcomissao;
    lucrocasa = parseFloat(lucrocasa.toFixed(2));
    lucrofora =  parseFloat(lucrofora.toFixed(2));
    //Lucro Total
    lucrototal = ((lucrocasa+lucrofora) / 2);
    lucrototal = parseFloat(lucrototal.toFixed(2));


    //SetVars
    jogo.casacomissao = casacomissao;
    jogo.foracomissao = foracomissao;
    jogo.taxacasa.besttaxacasa = BestTaxaCasa;
    jogo.taxafora.besttaxafora = BestTaxaFora;

    jogo.calculo.surebet = surebet;
    jogo.calculo.profit = profit;
    jogo.calculo.profitpercent = profitpercent;
    jogo.calculo.aportecasa = aportecasa;
    jogo.calculo.aportefora = aportefora;
    jogo.calculo.premiocasa = premiocasa;
    jogo.calculo.premiofora = premiofora;
    jogo.calculo.totaljogado = totaljogado;
    jogo.calculo.totalpremios = totalpremios;
    jogo.calculo.lucrocasa = lucrocasa;
    jogo.calculo.casacomissao = casacomissao;
    jogo.calculo.lucrofora = lucrofora; 
    jogo.calculo.foracomissao = foracomissao;
    jogo.calculo.lucrototal = lucrototal;
  }
}

  /**
   * Quando mudar a Cotação
   */
   async changeComissao(value: number, getjogo: ListaJogos, oddstype: string) {
    //Pegar informações do Aporte.
    await this.storage.get("aportebasesave").then(aportebasesave => {
      if (aportebasesave != null) {
        this.baseaposta = aportebasesave;
      }
    });

    if(oddstype == "baseaposta"){
      this.baseaposta = value;
    }

    const jogos: ListaJogos[] = this.getJogos();
    if (jogos) {

    let jogo = jogos.find(jogo => jogo.id === getjogo.id)

    //Começar Calculo Surebet
    //vars 
    var premiocasa = 0;
    var premioempate = 0;
    var premiofora = 0;
    var aportecasa = 0;
    var aporteempate = 0;
    var aportefora = 0;
    var totaljogado = 0;
    var totalpremios = 0;
    var totalcomissao = 0;
    var comissaocasa = 0;
    var comissaoempate = 0;
    var comissaofora = 0;
    var lucrocasa = 0;
    var lucroempate = 0;
    var lucrofora = 0;
    var lucrototal = 0;
    var coeficienteCasa = 0;
    var coeficienteEmpate = 0;
    var coeficienteFora = 0;
    var surebet = 0;
    var profit = 0;
    var profitpercent = 0;

    var BestTaxaCasa = jogo.taxacasa.besttaxacasa;
    var BestTaxaEmpate = jogo.taxaempate.besttaxaempate;
    var BestTaxaFora = jogo.taxafora.besttaxafora;
    var casacomissao =  jogo.casacomissao;
    var empatecomissao = jogo.empatecomissao;
    var foracomissao = jogo.foracomissao;


    if(oddstype == "casa"){
      casacomissao = value;
    }
    if(oddstype == "empate"){
      empatecomissao = value;
    }
    if(oddstype == "fora"){
      foracomissao = value;
    }

    //Iniciar Calculo
    coeficienteCasa = (1 / BestTaxaCasa);
    coeficienteEmpate = (1 / BestTaxaEmpate);
    coeficienteFora = (1 / BestTaxaFora);

    //Se a soma é menor que 1: É uma SureBet
    surebet = coeficienteCasa+coeficienteEmpate+coeficienteFora;
    profitpercent = (1 / (coeficienteCasa + coeficienteEmpate +  coeficienteFora) - 1) * 100;
    profitpercent = parseFloat(profitpercent.toFixed(2));
    profit = (this.baseaposta * profitpercent) / 100;
    profit = parseFloat(profit.toFixed(2));

    //Valor Aportado
    aportecasa = (this.baseaposta / surebet * coeficienteCasa);
    aporteempate = (this.baseaposta / surebet * coeficienteEmpate);
    aportefora = (this.baseaposta / surebet * coeficienteFora);
    aportecasa = parseFloat(aportecasa.toFixed(2));
    aporteempate = parseFloat(aporteempate.toFixed(2));
    aportefora = parseFloat(aportefora.toFixed(2));


    //converter depois do calculo
    surebet = parseFloat(surebet.toFixed(4));

    //Posivel Premio
    premiocasa = aportecasa * BestTaxaCasa;
    premioempate = aporteempate * BestTaxaEmpate;
    premiofora = aportefora * BestTaxaFora;
    premiocasa = parseFloat(premiocasa.toFixed(3));
    premioempate = parseFloat(premioempate.toFixed(3));
    premiofora = parseFloat(premiofora.toFixed(3));

    //Comissão de cada odds
    comissaocasa = (casacomissao/100) * aportecasa;
    comissaoempate = (empatecomissao/100) * aporteempate;
    comissaofora = (foracomissao/100) * aportefora;
    comissaocasa = parseFloat(comissaocasa.toFixed(3));
    comissaoempate = parseFloat(comissaoempate.toFixed(3));
    comissaofora = parseFloat(comissaofora.toFixed(3));

    //total das comissão.
    totalcomissao = comissaocasa+comissaoempate+comissaofora;      
    
    //Calculo Total
    totaljogado = aportecasa+aporteempate+aportefora;
    totaljogado = parseFloat(totaljogado.toFixed(2));
    totalpremios = ((premiocasa+premioempate+premiofora)/3) + totalcomissao;
    totalpremios = parseFloat(totalpremios.toFixed(2));

    //Lucro
    lucrocasa = (premiocasa - (aportecasa + aporteempate + aportefora)) + totalcomissao;
    lucroempate = (premioempate - (aportecasa + aporteempate + aportefora)) + totalcomissao;
    lucrofora = (premiofora - (aportecasa + aporteempate + aportefora)) + totalcomissao;
    lucrocasa = parseFloat(lucrocasa.toFixed(2));
    lucroempate = parseFloat(lucroempate.toFixed(2));
    lucrofora =  parseFloat(lucrofora.toFixed(2));
    //Lucro Total
    lucrototal = ((lucrocasa+lucroempate+lucrofora) / 3);
    lucrototal = parseFloat(lucrototal.toFixed(2));


    //SetVars
    jogo.casacomissao = casacomissao;
    jogo.empatecomissao = empatecomissao;
    jogo.foracomissao = foracomissao;
    jogo.taxacasa.besttaxacasa = BestTaxaCasa;
    jogo.taxaempate.besttaxaempate = BestTaxaEmpate;
    jogo.taxafora.besttaxafora = BestTaxaFora;

    jogo.calculo.surebet = surebet;
    jogo.calculo.profit = profit;
    jogo.calculo.profitpercent = profitpercent;
    jogo.calculo.aportecasa = aportecasa;
    jogo.calculo.aporteempate = aporteempate;
    jogo.calculo.aportefora = aportefora;
    jogo.calculo.premiocasa = premiocasa;
    jogo.calculo.premioempate = premioempate;
    jogo.calculo.premiofora = premiofora;
    jogo.calculo.totaljogado = totaljogado;
    jogo.calculo.totalpremios = totalpremios;
    jogo.calculo.lucrocasa = lucrocasa;
    jogo.calculo.casacomissao = casacomissao;
    jogo.calculo.lucroempate = lucroempate;
    jogo.calculo.empatecomissao = empatecomissao; 
    jogo.calculo.lucrofora = lucrofora; 
    jogo.calculo.foracomissao = foracomissao;
    jogo.calculo.lucrototal = lucrototal;
  }
}

  /**
   * Given a User object, sets the the credentials for the
   * @param user 
   */
  setUserApiCredentials(user: User) {
    if (user != null) {
      this.apiGateway.configuration.email = user.email;
      this.apiGateway.configuration.password = user.password;
    } else {
      this.apiGateway.configuration.email = null;
      this.apiGateway.configuration.password = null;
    }
    return;
  }

  /**
   * Returns the stored traccar basepath. If not value found,
   * the functions returns the address of one of the demo servers
   * of traccar
   */
    getBasePath(): Promise<string> {
      return this.storage.get('basepath').then((value) => {
        if (value) {
          return value;
        } else {
          return this.defaultBasePath;
        };
      });
    }

    /**
   * Sets the traccar basepath to the one stored in memory
   * If no value found, it is set to the address of one of
   * the demo servers of traccar
   */
     loadBasePathFromMemory() {
      return this.getBasePath().then(value => {
        this.apiGateway.basePath = value;
      })
    }
}
