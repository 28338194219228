import { SuremtsbetService } from './services/suremtsbet.service';
import { AlertController, ToastController } from '@ionic/angular';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UserData } from './providers/user-data';
import { Storage } from '@ionic/storage-angular';
import { Platform } from '@ionic/angular';
import { ApiService } from './services/api/api.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  loggedIn = false;
  listaconfig = [
    { site: 'bet365pay', taxa: 0, isChecked: true },
    //Plataforma BetsBola
    { site: 'betsbolapro', taxa: 0, isChecked: true },
    { site: 'esportenetsp', taxa: 0, isChecked: false },
    { site: 'esportenetvip', taxa: 0, isChecked: false },
    { site: 'mixbet', taxa: 0, isChecked: false },
    { site: 'mixbetvip', taxa: 0, isChecked: false },
    { site: 'pix99', taxa: 0, isChecked: false },
    //Plataforma SA
    { site: 'esportenet', taxa: 0, isChecked: false },
    { site: 'esportesdasorte', taxa: 0, isChecked: false },
    { site: 'betsbola', taxa: 0, isChecked: false },
    { site: 'nbet91com', taxa: 0, isChecked: false },
    { site: 'pliniobet', taxa: 0, isChecked: false },
    { site: 'sportmax', taxa: 0, isChecked: false },
    //Plataforma NGX
    { site: 'pitaco', taxa: 0, isChecked: false },
    { site: 'betfavorita', taxa: 0, isChecked: false },
    { site: 'dollarbet', taxa: 0, isChecked: false },
    { site: 'esporte365', taxa: 0, isChecked: false },
    { site: 'bet69pro', taxa: 0, isChecked: false },
    //Outras Plataforma
    { site: 'betano', taxa: 0, isChecked: false },
    { site: 'marjosports', taxa: 0, isChecked: false },
    { site: 'dafabet', taxa: 0, isChecked: false },
    { site: 'apostaganha', taxa: 0, isChecked: false },
    { site: 'pinnacle', taxa: 0, isChecked: false },
    { site: 'betfair', taxa: 0, isChecked: false },
    { site: 'galerabet', taxa: 0, isChecked: false },
    { site: '888sports', taxa: 0, isChecked: false },
    { site: '1xbet', taxa: 0, isChecked: false },
    { site: 'sportingbet', taxa: 0, isChecked: false },
    { site: 'pixbet', taxa: 0, isChecked: false },
    { site: 'leovegas', taxa: 0, isChecked: false },
    //Plataforma SGA
    { site: 'pagamaissports', taxa: 0, isChecked: false },
    { site: 'enetonline', taxa: 0, isChecked: false },
    { site: 'podersports', taxa: 0, isChecked: false },
    //Plataforma BET6
    { site: 'mrjack', taxa: 0, isChecked: false },
    { site: 'betnacional', taxa: 0, isChecked: false },
    //Plataforma PTB
    { site: 'vaidebet', taxa: 0, isChecked: false },
    { site: 'sorteonline', taxa: 0, isChecked: false },
    { site: 'estrelabet', taxa: 0, isChecked: false },
    { site: 'toisbet', taxa: 0, isChecked: false },
    { site: 'betpix365', taxa: 0, isChecked: false },
    //Plataforma xlargesoftware
    { site: 'lampionsbet', taxa: 0, isChecked: false },
    { site: 'esportivabet', taxa: 0, isChecked: false },
    //WEEPlataforma
    { site: 'primeesportes', taxa: 0, isChecked: false },
    { site: 'imperiopix', taxa: 0, isChecked: false }
  ];

  constructor(
    private platform: Platform,
    private router: Router,
    private storage: Storage,
    private userData: UserData,
    private SuremtsbetService: SuremtsbetService,
    private ApiService: ApiService,
    public toastCtrl: ToastController
  ) {
    this.initializeApp();
  }

  async ngOnInit() {
    await this.storage.create();
    this.checkLoginStatus();
    this.setUpSureMtsBet();
    this.listenForLoginEvents();
  }
 
  initializeApp() {
    this.platform.ready().then(() => {
      //Set Configurações
      this.storage.get("sitesconfigssave").then(sitesconfigssave => {
        if (sitesconfigssave == null) {
          this.storage.set("sitesconfigssave", this.listaconfig);
          this.SuremtsbetService.setSiteConfigs(this.listaconfig);
        }
      });
      this.storage.get("aportebasesave").then(aportebasesave => {
        if (aportebasesave == null) {
          this.storage.set("aportebasesave", 1000);
        }
      });
      this.storage.get("suremaxsave").then(suremaxsave => {
        if (suremaxsave == null) {
          this.storage.set("suremaxsave", 1.02);
        }
      });
    });
  }

  async consolelog(mensage: any){
    // Create a toast
    const toast = await this.toastCtrl.create({
      header: mensage,
      duration: 1500,
      buttons: [{
        text: "Fechar",
        role: 'cancel'
      }]
    });

    // Present the toast at the bottom of the page
    await toast.present();
  }

   /**
   * Making use of the suremtsbet service, sets up the
   * basepath of the service and the user credentials
   * for the Rest API
   */
    setUpSureMtsBet() {
      this.SuremtsbetService.loadBasePathFromMemory();
      return this.userData.getUser().then(user => {
        if (user) {
          //verificar token
          /*
          this.ApiService.tokenVerify(user.id.toString(), user.token, "body")
          .subscribe(response => {
            if(response['message'] != "ativo"){
              this.consolelog(response['message']);
              this.userData.logout().then(() => {
                this.SuremtsbetService.setUserApiCredentials(null);
                return this.router.navigateByUrl('/login');
              });
            }

          }, err => {
            console.error(err)
            this.userData.logout().then(() => {
              this.SuremtsbetService.setUserApiCredentials(null);
              return this.router.navigateByUrl('/login');
            });
          }, () => {
          });*/

          this.SuremtsbetService.setUserApiCredentials(user);
        }
      })
    }

    checkLoginStatus() {
      return this.userData.isLoggedIn().then(loggedIn => {
        return this.updateLoggedInStatus(loggedIn);
      });
    }

    updateLoggedInStatus(loggedIn: boolean) {
      setTimeout(() => {
        this.loggedIn = loggedIn;
      }, 300);
    }

    listenForLoginEvents() {
      window.addEventListener('user:login', () => {
        this.updateLoggedInStatus(true);
      });

      window.addEventListener('user:signup', () => {
        this.updateLoggedInStatus(true);
      });

      window.addEventListener('user:logout', () => {
        this.updateLoggedInStatus(false);
      });
    }

    logout() {
      this.userData.logout().then(() => {
        this.SuremtsbetService.setUserApiCredentials(null);
        return this.router.navigateByUrl('/login');
      });
    }
}
